import React, { useEffect, Fragment, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Spin, Row, Col, Typography, Card, Avatar, Table, Divider, Skeleton, Radio } from 'antd';

import styled from 'styled-components';
import CountUp from 'react-countup';
import { loadServices, loadServicesReports, loadServicesByVehicle, loadfleetsReports } from '../../store/services';
import { PermissionValidator } from '../shared/PermissionValidator';
import DownloadReports from './DownloadReports';

import BreadcrumbCustom from '../Custom/BreadcrumbCustom'
import CustomTable from '../Custom/CustomTable';
import ModuleHeader from '../Custom/ModuleHeader';
import VehiclesGroupCard from '../Custom/VehiclesGroupCard';
import FilterServices from './FilterServices';
import '../Custom/ScrollContainer.css';
import CustomRadioButton from '../Custom/CustomRadioButton';
import TaxCo2Card from '../Custom/TaxCo2Card';
import Labels from '../Custom/VolumeUnitLabel'

import SvgGasIcon from '../icons/GasIcon';
import SvgAmountIcon from '../icons/AmountIcon';
import SvgBusIcon from '../icons/BusIcon';
import SavedCO2 from '../icons/SavedCO2';
import TaxCredit from '../icons/TaxCredit';

import GallonIcon from '../icons/GallonIcon';
import AmountIcon from '../icons/AmountIcon';
import TransactionIcon from '../icons/TransactionIcon';
import GLSIcon from '../icons/GLSIcon';
import USDIcon from '../icons/USDIcon';
import SalesIcon from '../icons/SalesIcon';

import { useTranslation } from 'react-i18next';


const { Text } = Typography;

const StyledTable = styled(Table)`
        .ant-table-container {
            padding: 0px
        }
        th.ant-table-cell {
            background-color: ${props => props.theme.primaryColor}99;
        }
`;

const CustomCard = styled(Card)`
        ::before {
                position: absolute;
                top: 25px;
                left: 0;
                background-color: ${props => (props.color)};
                height: 50%;
                width: 5px;
                border-radius: 0px 6px 6px 0px;
                content: '';
            }
        @media (max-width: 850px) {
            .ant-typography  span {
                font-size: 22px !important;
            }
        }
`;

const CustomAvatar = styled(Avatar)`
        @media (max-width: 850px) {
            width: 0% !important;
        }
`;

const CustomDiv = styled.div`
        .container {
            position: relative;
            ::before {
                position: absolute;
                top: 8px;
                left: 0;
                background-color: #C2CFE0;
                height: 60%;
                width: 1px;
                content: '';
            }
        }
`;

const Services = () => {
    const { t } = useTranslation(['service', 'table_labels', 'common_labels', 'form_error_message', 'custom_input_labels']);
    const { hasResourcePermission, userHasResourcePermission } = PermissionValidator();
    const { getVolumeLabel, getCurrencyLabel } = Labels();
    const volumeLabel = getVolumeLabel();
    const currencyLabel = getCurrencyLabel();
    const cardGridStyle = {width: '75%', padding: '0px', paddingLeft: '1.5em', paddingTop: '.5em', boxShadow: 'none'};
    let accessStation = hasResourcePermission('access_station');
    let readSavedCO2 = userHasResourcePermission('read_co2_consumption');
    let readTextCredit = userHasResourcePermission('read_taxcredit');
    const dispatch = useDispatch();
    let { list, loading, reports, 
        loadingReport,
        loadingVehicle,
        groups: reportsGroups, loadingGroups } = useSelector(state => state.entities.services);
    const { user, config } = useSelector(state => state.entities.authentication);
    const { total_gallons, total_amount, services_number, co2_saved, tax_revenue } = reports;
    let filtered = []

    const [serviceSerch, setServiceSerch] = useState([]);
    const [searchValue, setSearch] = useState(null);
    const [group, setGroup] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [groupTotals, setGroupTotals] = useState(true);

    let today = new Date();
    let offset = today.getTimezoneOffset();

    let report_groups = [...reportsGroups];
    report_groups.sort((a, b) => a.name.localeCompare(b.name));

    /**
     * Moving the All Vehicles group to the
     * end of the groups array
     */
    let allVehiclesGroup = report_groups.find(
        group => group?.name === "All vehicles"
    )

    allVehiclesGroup && 
    report_groups.push(
        report_groups.splice(
            report_groups.indexOf(allVehiclesGroup), 1
        )[0]
    );


    let dataService = list.map(service => {
        const { 
            number, 
            user, 
            station, 
            product, 
            quantity, 
            dispenser_date, 
            invoice_amount, 
            price,
            vehicle,
            odometer
        } = service;

        let customer = t('not_identified_label');

        if (vehicle) {
            customer = vehicle.customer === "Not identified" 
                ? customer
                : vehicle.customer
        };

        return {
            quantity,
            "number": number.toString(),
            "created_at": new Date(dispenser_date).toLocaleString("en-US"),
            "user": user,
            "station": station,
            "product": product,
            "price": parseFloat(price).toFixed(2),
            "invoice_amount": parseFloat(invoice_amount).toFixed(2),
            "customer": customer,
            "unit_code": vehicle ? vehicle.unit_code : '',
            "plate_number": vehicle ? vehicle.plate_number : '',
            "odometer": odometer,
        }
    })

    const onChangeDateCalendar = (start, end) => {
        dispatch(loadServices(start, end, offset));
        
        if(!accessStation){
            dispatch(loadfleetsReports(start, end, offset));
        }

        dispatch(loadServicesReports(start, end, offset));
        setStartDate(start);
        setEndDate(end);
    }

    const handleSearch = (e) =>{
        let value = (e.target.value).trim().toLowerCase();
        filtered = dataService.filter(element => {
        const { user, product, number, created_at, station, customer } = element;
        return (product.name.toLowerCase().includes(value) ||
            number.includes(value) ||
            `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.includes(value) ||
            created_at.toLowerCase().includes(value) ||
            station.name.toLowerCase().includes(value) || customer.toLowerCase().includes(value));
        });
        setSearch(value);
        setServiceSerch(filtered);
    }
    
    useEffect(() => {
        var today = new Date();
        let params = `${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(-2)}-${today.getDate()}`;
        let offset = today.getTimezoneOffset();
        if(accessStation){
            dispatch(loadServices(params, params, offset));
        } else {
            dispatch(loadServices(params, params, offset));
            dispatch(loadfleetsReports(params, params, offset));
        }
        dispatch(loadServicesReports(params, params, offset));
        setStartDate(params);
        setEndDate(params);
    }, [dispatch, accessStation]);

    const [sortStatus, setSortStatus] = useState(false);

    const columns = [
        {
            dataIndex: 'number',
            id: 'number',
            render: (number) => <Text strong style={{ color: config.primaryColor }}> {number} </Text>
        },
        {
            dataIndex: 'user',
            key: 'user',
            render: (user) => <Text delete={user?.deleted_at}> {`${user?.first_name} ${user?.last_name}`} </Text>
        },
        {
            dataIndex: 'station',
            key: 'station',
            render: (station) => <Text delete={station?.deleted_at}> {station?.name} </Text>
        },
        {
            dataIndex: 'product',
            key: 'product',
            render: (product) => <Text delete={product?.deleted_at}> {product?.name}</Text>
        },
        {
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (price) => <Text>$ {price} </Text>
        },
        {
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            suffix: `(${volumeLabel.abbreviated})`,
            render: (quantity) => <Text>{parseFloat(quantity).toFixed(2)}</Text>

        },
        {
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            align: 'right',
            suffix: `(${currencyLabel.abbreviated})`,
            render: (invoice_amount) => <Text strong style={{ color: config.primaryColor }}>$ {invoice_amount}</Text>

        },
        {
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Text> {created_at} </Text>
        },
        {
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => <Text> {customer} </Text>
        }
    ];

    const columsVehicles = [
        {
            dataIndex: 'unit_code',
            id: 'unit_code',
            render: (unit_code) => <Text strong>#{unit_code} </Text>
        },
        {
            dataIndex: 'vehicle_model_name',
            key: 'vehicle_model_name',
            render: (vehicle_model_name) => <Text > {vehicle_model_name} </Text>
        },
        {
            dataIndex: 'vehicle_brand_name',
            key: 'vehicle_brand_name',
            render: (vehicle_brand_name) => <Text > {vehicle_brand_name} </Text>
        },
        {
            dataIndex: 'year',
            key: 'year',
            render: (year) => <Text> {year}</Text>
        },
        {
            dataIndex: 'drivers',
            key: 'drivers',
            render: (driver) => <Text> {driver[0]?.first_name} </Text>
        },
        {
            dataIndex: 'services_amount',
            key: 'services_amount',
            sorter: (a, b) => parseFloat(a.services_amount) - parseFloat(b.services_amount),
            sortOrder: null,
            render: (services_amount) => <Text>$ {services_amount?.toFixed(2)} { currencyLabel.abbreviated }</Text>
        },
        {
            dataIndex: 'total_gallons',
            key: 'total_gallons',
            render: (total_gallons) => <Text>{total_gallons?.toFixed(2)} { volumeLabel.abbreviated }</Text>

        },
        {
            dataIndex: 'miles_per_gallon',
            key: 'miles_per_gallon',
            sorter: (a, b) => a.miles_per_gallon - b.miles_per_gallon,
            sortOrder: null,
            render: (miles_per_gallon) => <Text> {
                miles_per_gallon ? 
                miles_per_gallon?.toFixed(2) : 
                "0.00"
            } { volumeLabel.distance }

            </Text>

        }
    ];

    const columnsExpanded = [
        {
            dataIndex: 'number',
            id: 'number',
            render: (number) => <Text strong style={{ color: config.primaryColor }}> {number} </Text>
        },
        {
            dataIndex: 'user',
            key: 'user',
            render: (user) => <Text > {user} </Text>
        },
        {
            dataIndex: 'station',
            key: 'station',
            render: (station) => <Text > {station} </Text>
        },
        {
            dataIndex: 'product',
            key: 'product',
            render: (product) => <Text > {product}</Text>
        },
        {
            dataIndex: 'price',
            key: 'price',
            render: (price) => <Text>$ {parseFloat(price).toFixed(2)} </Text>
        },
        {
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity) => <Text>{parseFloat(quantity).toFixed(2)}</Text>

        },
        {
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            render: (invoice_amount) => <Text strong style={{ color: groupTotals ? config.primaryColor : 'initial'}}>$ {parseFloat(invoice_amount).toFixed(2)}</Text>

        },
        {
            dataIndex: 'odometer',
            key: 'odometer',
            render: (odometer) => <Text> {odometer} </Text>
        },
        {
            dataIndex: 'dispenser_date',
            key: 'dispenser_date',
            render: (dispenser_date) => <Text> {new Date(dispenser_date).toLocaleString("en-US")} </Text>
        },
    ];


    const serviceByVehicle = (vehicle) => {
        dispatch(loadServicesByVehicle(startDate, endDate, vehicle.id, offset));
    }

    if (Object.entries(group).length === 0 && reportsGroups.length > 0) {
        setGroup(reportsGroups[0])
    }

    let vehiclesData = []

    if (group) {
        vehiclesData = reportsGroups.find(e => e.id === group.id);
        if (vehiclesData) {
            vehiclesData = vehiclesData.vehicles;
        }
    }

    const sort_performance = (order) => {
        setSortStatus(order);
    }

    const handle_filter_by_total = (e) => {
        let value = e.target['value'];
        setGroupTotals(value);
    }

    return (
        <Fragment>
            <Row className='mb-3' style={{ zIndex: '3' }}>
                <Col lg={11} xs={12} xl={10}>
                    <ModuleHeader>
                        {accessStation ? t('header1') : t('header2')}
                    </ModuleHeader>
                </Col>
                {/*
                    IMPORTANTE!!! LOS SIGUIENTES CASOS TIENEN QUE SER REFACTORIZADOS
                */}
                {readSavedCO2 &&
                    <Col 
                        className='mr-0'
                        lg={!accessStation ? 7 : 8} 
                        xl={{ span: !accessStation ? 5 : 8, offset: 5 }}
                        style={{
                            margin: 'auto'
                        }}
                    >
                        <TaxCo2Card values={[{label: 'Pounds Of Co2 Saved', value: co2_saved}, {label: 'Tax Credit', value: tax_revenue}]} />
                    </Col>
                }
                {readSavedCO2 &&
                    <Col 
                        lg={{ span: !accessStation ? 5 : 6, offset: 1 }}
                        xl={{ span:3, offset: 1 }}
                        className='pr-1 mr-0' 
                        offset={!readSavedCO2 && 5}
                        style={{
                            margin: 'auto'
                        }}
                    >
                        <DownloadReports translationFunction={t} reportType={!accessStation} data={searchValue ? serviceSerch : dataService} />
                    </Col>
                }
                {readTextCredit &&
                    <Col 
                        className='mr-0'
                        xs={!accessStation ? 6 : 5} 
                        md={{ offset: 1 }} 
                        lg={{ span: !accessStation ? 6 : 4, offset: 3 }}
                        xl={{ span: 3, offset: 7 }} 
                        style={{
                            margin: 'auto'
                        }}
                    >
                        <TaxCo2Card values={[{label: 'Tax Credit', value: tax_revenue}]} />
                    </Col>
                }
                {readTextCredit &&
                    <Col 
                        xs={{ span: 7, offset: 1 }} 
                        md={{ span: 6, offset: 0 }} 
                        lg={{ span: !accessStation ? 2 : 4, offset: 1 }}
                        xl={{ span: 3, offset: 1 }} 
                        className='pr-1 mr-0' 
                        offset={!readTextCredit && 5}
                        style={{
                            margin: 'auto'
                        }} 
                    >
                        <DownloadReports translationFunction={t} reportType={!accessStation} data={searchValue ? serviceSerch : dataService} />
                    </Col>
                }
                {!readTextCredit && !readSavedCO2 &&
                    <Col 
                        lg={!accessStation ? 7 : 6} 
                        xl={9} 
                        className='pr-1 mr-0' 
                        offset={!readTextCredit && 5} 
                        style={{
                            margin: 'auto'
                        }}
                    >
                        <DownloadReports translationFunction={t} reportType={!accessStation} data={searchValue ? serviceSerch : dataService} />
                    </Col>
                }
            </Row>
            <Row className="p-2" style={{ backgroundColor: '#FFF', zIndex: '3', borderRadius: '10px', boxShadow: '0px 3px 10px #C2CFE066' }} >
                <Col xl={24}>
                    <FilterServices 
                        accessStation={accessStation} 
                        onChangeDateCalendar={onChangeDateCalendar} 
                        handleSearch={handleSearch} 
                        handleFilter={handle_filter_by_total}
                        data={searchValue ? serviceSerch : dataService}
                        fleetReport={!accessStation}
                        co2_saved={co2_saved}
                        tax_revenue={tax_revenue}
                        readSavedCO2={readSavedCO2} 
                        translationFunction={t}
                     />
                </Col>
            </Row>
                <Row>
                    <Col xs={24} sm={0} className='pt-3'>
                        <p style={{ margin: '2px' }} >
                            <div style={{ marginRight: '2vh', display: 'inline-block' }}>
                                <Avatar shape="square" className='float-right' size={25} 
                                        style={{ 
                                            backgroundColor: '#3D80EA26' 
                                            }} 
                                    >
                                    {<SvgGasIcon />} 
                                </Avatar>
                            </div>
                            <Text>{t('data_cards.total_gallons')}:</Text>
                            <Text style={{ fontSize: '15px', paddingLeft: '1vh' }}>
                                    <CountUp
                                        start={0}
                                        decimal="."
                                        end={total_amount}
                                        separator=","
                                        prefix="$ "
                                        decimals={2}
                                    />
                            </Text>
                        </p>

                        <Divider className='m-0' />

                        <p style={{ margin: '2px' }} >
                            <div style={{ marginRight: '2vh', display: 'inline-block' }}>
                                <Avatar shape="square" className='float-right' size={25} 
                                        style={{ 
                                            backgroundColor: '#D6F1ED' 
                                            }} 
                                    >
                                    {<AmountIcon />} 
                                </Avatar>
                            </div>
                            <Text>{t('data_cards.total_amount')}:</Text>
                            <Text style={{ fontSize: '15px', paddingLeft: '1vh' }}>
                                <CountUp
                                    start={0}
                                    decimal="."
                                    end={total_amount}
                                    separator=","
                                    prefix="$ "
                                    decimals={2}
                                />
                            </Text>
                        </p>

                        <Divider className='m-0' />

                        <p style={{ margin: '2px' }} >
                            <div style={{ marginRight: '2vh', display: 'inline-block' }}>
                                <Avatar shape="square" className='float-right' size={25} 
                                        style={{ 
                                            backgroundColor: '#F4E4F8' 
                                            }} 
                                    >
                                    {<SvgBusIcon />} 
                                </Avatar>
                            </div>
                            <Text>{t('data_cards.total_transactions')}:</Text>
                            <Text style={{ fontSize: '15px', paddingLeft: '1vh' }}>
                                <CountUp
                                    start={0}
                                    decimal="."
                                    end={services_number}
                                    separator=","
                                    decimals={0}
                                />
                            </Text>
                        </p>
                    </Col>
                </Row>

                {/*
                    IMPORTANTE!!! LOS SIGUIENTES COMPONENTES TIENEN QUE SER REFACTORIZADOS Y MOVIDOS A UN COMPONENTE APARTE
                */}
                <Row gutter={16} className='mb-3'>
                    
                    <Col xs={0} sm={8} md={8} lg={8} className='mt-3' >
                        <CustomCard color={'#905EFD'} size='small' loading={loadingReport} style={{ height: '100%', boxShadow: '0px 3px 10px #C2CFE066', borderRadius: '10px', paddingTop: '1em', paddingBottom: '1em' }}>
                            <div style={{ width: '25%', textAlign: 'center', float: 'right', marginTop: '10px' }}>
                                <CustomAvatar shape="square" size={52} style={{ backgroundColor: 'transparent'}} >
                                    {<GallonIcon style={{ marginTop: '16px' }} />}
                                </CustomAvatar>
                            </div>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text type='secondary' style={{ fontSize: '14px' }}>
                                    { t('data_cards.total_gallons') } { volumeLabel.plural.toUpperCase() }
                                </Text>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text className='pr-1' strong style={{ fontSize: '26px' }}>
                                    <CountUp
                                        start={0}
                                        decimal="."
                                        end={total_gallons}
                                        separator=","
                                        decimals={2}Ser
                                    />
                                </Text>
                                { volumeLabel.abbreviated.toUpperCase() }
                            </Card.Grid>
                        </CustomCard>
                    </Col>

                    <Col xs={0} sm={8} md={8} lg={8} className='mt-3' >
                        <CustomCard color={'#27C695'} size='small' loading={loadingReport} style={{ height: '100%', boxShadow: '0px 3px 10px #C2CFE066', borderRadius: '10px', paddingTop: '1em', paddingBottom: '1em' }}>
                            <div style={{ width: '25%', textAlign: 'center', float: 'right', marginTop: '10px' }}>
                                <CustomAvatar shape="square" size={52} style={{ backgroundColor: 'transparent'}} >
                                    {<AmountIcon style={{ marginTop: '16px' }} />}
                                </CustomAvatar>
                            </div>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text type='secondary' style={{ fontSize: '14px' }}>
                                    {t('data_cards.total_amount')}
                                </Text>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text className='pr-1' strong style={{ fontSize: '26px' }}>
                                    <CountUp
                                        start={0}
                                        decimal="."
                                        end={total_amount}
                                        separator=","
                                        prefix="$ "
                                        decimals={2}
                                    />
                                </Text>
                                {
                                    currencyLabel && currencyLabel.abbreviated.toUpperCase()
                                }
                            </Card.Grid>
                        </CustomCard>
                    </Col>

                    <Col xs={0} sm={8} md={8} lg={8} className='mt-3' >
                        <CustomCard color={'#3D80EA'} size='small' loading={loadingReport} style={{ height: '100%', boxShadow: '0px 3px 10px #C2CFE066', borderRadius: '10px', paddingTop: '.8em', paddingBottom: '.8em' }}>
                            <div style={{ width: '25%', textAlign: 'center', float: 'right', marginTop: '10px' }}>
                                <CustomAvatar shape="square" size={52} style={{ backgroundColor: 'transparent'}} >
                                    {<TransactionIcon style={{ marginTop: '16px'}} />}
                                </CustomAvatar>
                            </div>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text type='secondary' style={{ fontSize: '14px' }}>
                                    {t('data_cards.total_transactions')}
                                </Text>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={cardGridStyle}>
                                <Text strong style={{ fontSize: '26px' }}>
                                    <CountUp
                                        start={0}
                                        end={services_number}
                                        separator=","
                                    />
                                </Text>
                            </Card.Grid>
                        </CustomCard>
                    </Col>
                </Row>
            { !accessStation ?
                <Spin spinning={loadingGroups}>
                        {!!report_groups.length && report_groups.map(group => 
                            <VehiclesGroupCard
                                dataSource={accessStation ? (searchValue ? serviceSerch : dataService) : group.vehicles}
                                loading={accessStation ? loading : loadingGroups}
                                columns={accessStation ? columns : columsVehicles}
                                totals_filter={groupTotals}
                                group={group}
                                totalGallons={total_gallons}
                                totalAmount={total_amount}
                                servicesNumber={services_number}
                                sort_performance={sort_performance}
                                order={sortStatus}
                                translationFunction={t}
                            />
                        ) 
                        }
                </Spin>
                :
                <Row xs={24} md={24} gutter={16} >
                    <Col xs={24} md={accessStation ? 24 : 18} className="gutter-row">
                        <Row xs={24}  md={24} style={{ backgroundColor: '#FFF'}}>
                            <Col xs={24} className='p-3' >
                                <CustomTable
                                    locale={{emptyText: t('no_data', {ns: 'common_labels'})}}
                                    columns={accessStation ? columns : columsVehicles}
                                    dataSource={accessStation ? (searchValue ? serviceSerch : dataService) : vehiclesData  }
                                    rowKey='id'
                                    loading={accessStation ? loading : loadingGroups}
                                    expandable={accessStation ? {} : {
                                        expandedRowRender: (record) => {
                                            const { services } = record;
                                            return (
                                                loadingVehicle && services.length === 0 ? <Skeleton />
                                                : <StyledTable style={{ padding: '0px' }}
                                                columns={columnsExpanded}
                                                dataSource={services}
                                                pagination={false} />
                                                
                                                )
                                        },
                                        onExpand: (expanded, record) => expanded ? serviceByVehicle(record) : null
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Fragment>
    );
}

export default Services;
